import { FillableBy } from "./form_enums";
import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields } from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

  export default {
    fillable_by:FillableBy.Both,
    title:
      "Clinical Competency Checklist for LivaNova S5 Heart-Lung Machine",
    sections: [
      // {...employeeinfo},
      {
        title: "Device Setup",
        type: "table",
        heads: constants.tableHeads,
        rows:[
          "Performs initial inspection of the machine for cleanliness and physical integrity.",
          "Verifies all power connections and integrity of the power cord.",
          "Assembles and installs all required components (e.g., oxygenator, reservoir, tubing).",
          "Ensures the device is properly primed and free of air bubbles.",
          "Calibrates flow sensors and pressure monitors as needed."
        ].map((item) => ([ {text: item, type: 'text' }, ...table_fields ])),
      },
      {
        title: "Operation",
        type: "table",
        heads: constants.tableHeads,
        rows:[
          "Turns on the device and performs pre-use self-tests as recommended by the manufacturer.",
          "Configures appropriate settings for the patient's procedure (e.g., flow rate, pressure limits).",
          "Monitors pump performance and adjusts settings during the procedure as needed.",
          "Demonstrates proficiency in using the control panel and understanding indicator lights.",
          "Responds promptly and appropriately to any alarms or alerts during operation.",
        ].map((item) => ([ {text: item, type: 'text' }, ...table_fields ])),
      },
      {
        title: "Safety and Infection Control",
        type: "table",
        heads:constants.tableHeads,
        rows:[
          "Ensures all components in contact with blood are sterile and within expiration dates.",
          "Follows protocols for setup and maintenance to minimize contamination risk.",
          "Adheres to manufacturer’s cleaning and disinfection guidelines post-procedure.",
          "Documents maintenance and sterilization activities accurately.",
          "Positions the machine to ensure optimal safety for both the patient and the surgical team."
        ].map((item) => ([ {text: item, type: 'text' }, ...table_fields ])),
      },
      {
        title: "Troubleshooting and Emergency Procedures",
        type: "table",
        heads: constants.tableHeads,
        rows:[
          "Identifies and interprets all machine alarms.",
          "Troubleshoots common issues such as low flow, air bubble detection, or pressure anomalies.",
          "Implements contingency plans for device failure, including manual operation if needed.",
          "Communicates effectively with the surgical team regarding machine status and any issues.",
          "Escalates unresolved problems to technical support or biomedical engineering.",
        ].map((item) => ([ {text: item, type: 'text' }, ...table_fields ])),
      },
      {
        title: "Documentation",
        type: "table",
        heads: constants.tableHeads,
        rows:[
          "Records all pre-procedure checks and calibrations.",
          "Logs any deviations or malfunctions during the procedure in the patient record.",
          "Completes post-procedure documentation, including maintenance logs.",
          "Tracks usage of disposable components for inventory and billing purposes.",
          "Maintains detailed records for compliance with regulatory requirements.",
        ].map((item) => ([ {text: item, type: 'text' }, ...table_fields ])),
      },
      {...evaluationCheckList},
      {
        title: "Signatures",
        type: "signature",
        fields: getSignatures(FillableBy.Both)
      },
    ],
  }